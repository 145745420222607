body {
    margin: 0 !important;
    padding: 0 !important;
    background-color: #464646 !important;
    height: 100vh !important;
  }
  #login .container #login-row #login-column #login-box {
    margin-top: 120px !important;
    max-width: 600px !important;
    height: 320px !important;
    border: 1px solid #9C9C9C !important;
    background-color: #EAEAEA !important;
  }
  #login .container #login-row #login-column #login-box #login-form {
    padding: 20px !important;
  }
  #login .container #login-row #login-column #login-box #login-form #register-link {
    margin-top: -85px !important;
  }
  .form-control{
      height: 40px !important;
  }